@charset "UTF-8";
h1 {
  font: normal normal bold 45px/57px "Open Sans", sans-serif;
  color: #555555;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-bottom: 60px;
}
@media (max-width: 959px) {
  h1 {
    font: normal normal bold 30px/36px "Open Sans", sans-serif;
    margin-bottom: 40px;
  }
}

h2 {
  margin-bottom: 0px;
  font: normal normal normal 35px/45px "Open Sans", sans-serif;
  color: #707070;
  padding-bottom: 35px;
}
@media (max-width: 959px) {
  h2 {
    font: normal normal normal 20px/30px "Open Sans", sans-serif;
    padding-bottom: 30px;
  }
}

h3 {
  font: normal normal bold 23px/30px "Open Sans", sans-serif;
  color: #555555;
  letter-spacing: 0.57px;
  margin-bottom: 12px;
}
@media (max-width: 599px) {
  h3 {
    font: normal normal bold 19px/30px "Open Sans", sans-serif;
  }
}

h4 {
  transition: all 0.3s ease-in-out;
  font: normal normal 600 18px/30px "Open Sans", sans-serif;
  letter-spacing: 0.5px;
  opacity: 1;
  color: rgba(85, 85, 85, 0.6);
  padding: 0;
  margin-top: -12px;
  margin-bottom: 10px;
}

hr {
  width: 38px;
  height: 0px;
  border: 1px solid #FE4D18;
  background-color: #FE4D18;
  margin: 0px 0 0px 0;
  border-radius: 50px;
  padding: 1.4px;
}
@media (max-width: 600px) {
  hr {
    width: 23px;
    margin: 0px 0 4px 0;
  }
}

.txtbold {
  font-weight: 600;
}

.row {
  flex-flow: row;
  box-sizing: border-box;
  display: flex;
}

.rownowrap {
  flex-flow: row nowrap;
  box-sizing: border-box;
  display: flex;
}

.rowwrap {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
}

.column {
  flex-flow: column;
  box-sizing: border-box;
  display: flex;
}

.centercenter {
  place-content: center;
  align-items: center;
}

.centerstart {
  place-content: flex-start center;
  align-items: flex-start;
}

.centerend {
  place-content: flex-end center;
  align-items: flex-end;
}

.endcenter {
  place-content: center flex-end;
  align-items: flex-end;
}

.startcenter {
  place-content: center flex-start;
  align-items: center;
}

.startend {
  place-content: flex-end flex-start;
  align-items: flex-end;
}

.endstart {
  place-content: flex-start flex-end;
  align-items: flex-start;
}

@media (max-width: 959px) {
  .nbspltmd::before {
    content: " ";
  }
}
@media (min-width: 959px) {
  .nbspltmd::before {
    content: " ";
  }
}

@media (max-width: 959px) {
  .nbspgtmd::before {
    content: " ";
  }
}
@media (min-width: 959px) {
  .nbspgtmd::before {
    content: " ";
  }
}

.hiddengtmd {
  display: inline;
}
@media (min-width: 959px) {
  .hiddengtmd {
    display: none;
  }
}

.hiddenltmd {
  white-space: pre;
  display: inline-block;
}
@media (max-width: 959px) {
  .hiddenltmd {
    display: none;
  }
}

.selfcenter {
  align-self: center;
}

.selfstart {
  align-self: start;
}

.selfend {
  align-self: end;
}

.totheright {
  margin-left: auto;
}

.endcenter {
  place-content: center flex-end;
  align-items: center;
}

.startend {
  place-content: flex-end flex-start;
  align-items: flex-end;
}

@media (max-width: 959px) {
  .centermd {
    place-content: center;
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 599px) {
  .startsm {
    place-content: start;
    align-items: start;
    text-align: start;
  }
}

@media (max-width: 959px) {
  .w50 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 100%;
  }
}
@media (min-width: 959px) {
  .w50 {
    box-sizing: border-box;
    flex: 1 1 50%;
    max-width: 50%;
  }
}

@media (min-width: 599px) {
  .hidden_gtsm {
    display: none;
  }
}

.responsivepadding {
  padding-right: 142px;
  padding-left: 142px;
}
@media (min-width: 1599px) {
  .responsivepadding {
    max-width: 1599px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 1279px) {
  .responsivepadding {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 959px) {
  .responsivepadding {
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 30px;
  }
}
@media (max-width: 599px) {
  .responsivepadding {
    padding-left: 20px;
    padding-right: 18px;
  }
}

@media (max-width: 599px) {
  .resprow {
    flex-direction: column;
  }
}

@font-face {
  font-family: "CircularBook";
  src: url("/assets/fonts/CircularStd-Book.woff2") format("woff2"), url("/assets/fonts/CircularStd-Book.ttf") format("truetype");
  font-display: swap;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url("/assets/fonts/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk5hkWVAewA.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("/assets/fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url("/assets/fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url("/assets/fonts/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "CircularMedium";
  src: url("/assets/fonts/CircularStd-Medium.woff2") format("woff2"), url("/assets/fonts/CircularStd-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "CircularBold";
  src: url("/assets/fonts/CircularStd-Bold.woff2") format("woff2"), url("/assets/fonts/CircularStd-Bold.ttf") format("truetype");
  font-display: swap;
}
body {
  font-size: 20px;
  font-family: "CircularBook", sans-serif;
  color: #707070;
}

a {
  text-decoration: none;
  color: #707070;
}

#smooth-content {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.dftWrapper {
  background-color: white;
  position: relative;
  z-index: 2;
  padding-top: 135px;
  font: normal normal normal 20px/30px "CircularBook", sans-serif;
  letter-spacing: 0.5px;
}
@media (max-width: 599px) {
  .dftWrapper {
    padding-top: 80px;
    font: normal normal normal 16px/30px "CircularBook", sans-serif;
    letter-spacing: 0.4px;
  }
}

.contactLink {
  font-weight: normal;
  font-family: "CircularBook", sans-serif;
  font-size: 16px;
  color: #FE4D18;
  width: 140px;
  letter-spacing: 0.4px;
  color: #FE4D18;
}
@media (max-width: 599px) {
  .contactLink {
    margin-left: 8px;
    font-size: 15px;
    width: 130px;
    letter-spacing: 0.2px;
  }
}
.contactLink div {
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
  white-space: nowrap;
  width: 120px;
}
@media (max-width: 599px) {
  .contactLink div {
    width: 110px;
  }
}

.contactLink:hover div {
  margin-left: 12px;
  width: 145px;
}
@media (max-width: 599px) {
  .contactLink:hover div {
    margin-left: 4px;
    width: 135px;
  }
}

.puce {
  border-radius: 50px;
  background-color: #FE4D18;
  padding: 7.5px;
  height: 0px;
  width: 0px;
  margin-bottom: 10px;
  margin-right: 15px;
}
@media (max-width: 959px) {
  .puce {
    margin-bottom: 7px;
  }
}
@media (max-width: 599px) {
  .puce {
    padding: 6.5px;
  }
}

.bgblue {
  background-color: #234e7f;
}

.bgyellow {
  background-color: #ffbb00;
}

.bgdarkgrey {
  background-color: #555555;
}

.MysButton {
  width: fit-content;
}

.MysCard {
  width: 190px;
  height: 130px;
  background-color: #a7b8cb;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  place-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
}
@media (max-width: 959px) {
  .MysCard {
    width: 146px;
    height: 100px;
  }
}

.ffi-invalid-feedback-text {
  color: #FE4D18;
}

body {
  margin: 0;
  padding: 0;
}

.hidden {
  display: none;
}